import React from 'react';
import background from '../../../media/images/navbar.svg';


function Footer() {
  return (
    <footer className="footer color-white" style={{ backgroundImage: `url(${background})` }}>
        <p>Copyright ©  2022 CarClues (Pvt) Ltd. - All Rights Reserved.</p>
        <p>Terms of Service |  Privacy Policy</p>
        <p>Reproduction of  from any CarClues.pk pages without permission is strictly prohibited.</p>
    </footer>
  )
}

export default Footer;
