import * as React from 'react';
import { Card, Box, Grid, CardContent, Button, Typography, TextField } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";

var searchQuery = ""

export default function SearchBox(props) {
    var navigate = useNavigate(); 
    return (
        <Card sx={{ maxWidth: 1200, border: "2px ", borderRadius: "16px", boxShadow: "0px 2px 16px rgba(34, 24, 87, 0.5)", padding: "0em" }}>
            <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
                <Grid item lg={10} md={12} sm={12} xs={12}>
                    <CardContent >
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Button sx={{ padding: "7px" }} variant='contained'><SortIcon />Filter<ArrowDropDownIcon /></Button>
                            <Button sx={{ padding: "5px" }} onClick={(e) => {navigate('/search/' + searchQuery)}}><SearchIcon htmlColor='#1565C0'/></Button>
                            <TextField color='#1565C0' fullWidth  placeholder='Search by Car Model, Brand or Type' inputMode='text' InputProps={{ disableUnderline: true }} sx={{ color: "#1565C0" }} autoFocus={true} variant="standard" onKeyDown={(e) => {searchQuery = e.target.value; if(e.key==='Enter')navigate('/search/' + searchQuery)} } />
                        </Box>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
} 
