import React, { Component, setState } from 'react';
import { Typography, Grid, CardMedia, Chip, ToggleButton, TextField, Box, CardContent, Button, Card } from "@mui/material";
import SearchCard from './Components/searchCard';
import Footer from './Components/Footer';
import car from '../../media/images/car.jpeg'
import Navbar from './Components/Navbar';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useEffect, useState } from 'react';
// class Room extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             cars: [],
//         }
//     }

//     componentDidMount() {
//         // this.fetchData(this.props.params.search)
//         //     .then(res => this.setState({ cars: res.data}))
//         //     .catch(err => console.log(err));
//     }

//     dummyItems() {
//         var roomName
//         var noOfMembers
//         var dummies = []
//         for (var i = 0; i < 10; i++) {
//             roomName = 'Room Name'
//             noOfMembers = 'Members Count'
//             dummies.push(<SearchCard roomName={roomName} noOfMembers={noOfMembers} />)
//         }
//         return dummies.map(item => (<Grid sx={{ maxHeight: 200, marginTop: "1em" }} item xs={12} md={12} sm={12} lg={12}>{item}</Grid>))
//     }

//     render() {
//         return (
//             <div>
//                 <Navbar />
//                 {/* <Grid container spacing={0} style={{ height:"100%", padding: "1em", backgroundColor: "white", justifyContent: "center" }}> */}
//                 <div style={{height:"100%"}}>
//                     <Grid container spacing={4} style={{ maxWidth: "1200px", display: "flex", justifyContent: "center", paddingTop: "1em" }}>
//                         <Grid item lg={4} xs={4}>
//                             <Grid container spacing={1} sx={{ border: "2px solid", borderColor: "primary.dark", borderRadius: "16px", boxShadow: "0px 2px 8px rgba(34, 24, 87, 0.5)", display: "flex", flexDirection: "column", marginTop: "1em", padding: "0" }}>
//                                 <Typography sx={{ backgroundColor: "primary.dark", borderTopLeftRadius: "16px", borderTopRightRadius: "16px", color: "white", width: "100%", textAlign: "center", padding: "8px" }} fontWeight={600}>Members</Typography>
//                                 <Grid item lg={12} xs={12}>
//                                     <Card>
//                                         <CardContent >
//                                             <Typography variant="body1" fontWeight="600" color="primary.dark" >
//                                                 Car Talk
//                                             </Typography>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                                 <Grid item lg={12} xs={12}>
//                                     <Card>
//                                         <CardContent >
//                                             <Typography variant="body1" fontWeight="600" color="primary.dark" >
//                                                 Car Talk
//                                             </Typography>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                         <Grid item lg={8} md={8} sm={8} xs={8} justifyContent="center" sx={{ height: "100%" }}>
//                             {/* <Grid container spacing={1} sx={{ border: "2px solid", borderColor: "primary.dark", borderRadius: "16px", boxShadow: "0px 2px 8px rgba(34, 24, 87, 0.5)", marginTop: "1em", padding: "0", display: "flex", flexDirection: "column" }}>
//                                 <Typography sx={{ backgroundColor: "primary.dark", borderTopLeftRadius: "16px", borderTopRightRadius: "16px", color: "white", width: "100%", padding: "8px" }} fontWeight={600}>Room Name</Typography>
//                                 <Grid item lg={12} xs={12}>
//                                     <Chip sx={{ backgroundColor: "primary.dark", color: "white" }} label="User's Message" variant="filled" />
//                                 </Grid>
//                                 <Grid item lg={12} xs={12}>
//                                     <Chip label="Other's Message" variant="outlined" />
//                                 </Grid>
//                             </Grid> */}
//                             {/* Chat Window */}

//                         </Grid>

//                     </Grid>

//                     {/* </Grid> */}
//                 </div>
//                 <Footer />
//             </div>
//         )
//     }
// }

// export default Room

export default function Room() {
    const [messages, setMessages] = useState([]);

    // useEffect(() => {
    //     socket.on('message', message => {
    //         setMessages([...messages, message]);
    //     })
    // }, [messages]);

    // const fetchData = async () => {
    //     const response = await axios.get('http://localhost:5000/api/v1/cars');
    //     return response;
    // }

    // useEffect(() => {
    //     fetchData()
    //         .then(res => setMessages(res.data))
    //         .catch(err => console.log(err));
    // }, []);

    const dummyItems = () => {
        var roomName
        var noOfMembers
        var dummies = []
        for (var i = 0; i < 10; i++) {
            roomName = 'Room Name'
            noOfMembers = 'Members Count'
            dummies.push(<SearchCard roomName={roomName} noOfMembers={noOfMembers} />)
        }
        return dummies.map(item => (<Grid sx={{ maxHeight: 200, marginTop: "1em" }} item xs={12} md={12} sm={12} lg={12}>{item}</Grid>))
    }

    return (
        <div>
            <Navbar />
            {/* <Grid container spacing={0} style={{ height:"100%", padding: "1em", backgroundColor: "white", justifyContent: "center" }}> */}
            <div style={{ height: "100%" }}>
                <Grid container spacing={4} style={{ maxWidth: "1200px", display: "flex", justifyContent: "center", paddingTop: "1em" }}>
                    <Grid item lg={4} xs={4}>
                        <Grid container spacing={1} sx={{ border: "2px solid", borderColor: "primary.dark", borderRadius: "16px", boxShadow: "0px 2px 8px rgba(34, 24, 87, 0.5)", display: "flex", flexDirection: "column", marginTop: "1em", padding: "0" }}>
                            <Typography sx={{ backgroundColor: "primary.dark", borderTopLeftRadius: "16px", borderTopRightRadius: "16px", color: "white", width: "100%", textAlign: "center", padding: "8px" }} fontWeight={600}>Members</Typography>
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <CardContent >
                                        <Typography variant="body1" fontWeight="600" color="primary.dark" >
                                            Car Talk
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <CardContent >
                                        <Typography variant="body1" fontWeight="600" color="primary.dark" >
                                            Car Talk
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} justifyContent="center" sx={{ height: "100%" }}>
                        <Grid container spacing={1} sx={{ border: "2px solid", borderColor: "primary.dark", borderRadius: "16px", boxShadow: "0px 2px 8px rgba(34, 24, 87, 0.5)", marginTop: "1em", padding: "0", display: "flex", flexDirection: "column" }}>
                            <Typography sx={{ backgroundColor: "primary.dark", borderTopLeftRadius: "16px", borderTopRightRadius: "16px", color: "white", width: "100%", padding: "8px" }} fontWeight={600}>Room Name</Typography>
                            <Grid item lg={12} xs={12}>
                                <Chip sx={{ backgroundColor: "primary.dark", color: "white" }} label="User's Message" variant="filled" />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Chip label="Other's Message" variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </div>
            <Footer />
        </div>
    );
}