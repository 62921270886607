import React, { Component, setState } from 'react';
import { Typography, Grid, CardMedia, Chip, ToggleButton, TextField, Box, CardContent, Button, Card } from "@mui/material";
import SearchCard from './Components/searchCard';
import Footer from './Components/Footer';
import car from '../../media/images/car.jpeg'
import Navbar from './Components/Navbar';
import SearchIcon from '@mui/icons-material/Search';

class Community extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cars: [],
        }
    }

    componentDidMount() {
        // this.fetchData(this.props.params.search)
        //     .then(res => this.setState({ cars: res.data}))
        //     .catch(err => console.log(err));
    }

    dummyItems() {
        var roomName
        var noOfMembers
        var dummies = []
        for (var i = 0; i < 10; i++) {
            roomName = 'Room Name'
            noOfMembers = 'Members Count'
            dummies.push(<SearchCard roomName={roomName} noOfMembers={noOfMembers} />)
        }
        return dummies.map(item => (<Grid sx={{ maxHeight: 200, marginTop: "1em" }} item xs={12} md={12} sm={12} lg={12}>{item}</Grid>))
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Navbar />
                <Grid container spacing={0} style={{ marginTop: "72px", padding: "1em", backgroundColor: "white", justifyContent: "center" }}>
                    <Grid container spacing={1} style={{ margin: 0, maxWidth: "1200px", paddingTop: "1em", flexDirection: "row", textAlign: "left" }} >
                        <Grid item lg={8} md={8} sm={12} xs={12} justifyContent="center">
                            <Typography fontWeight={700} variant="h5">Available Communities</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} justifyContent="center">
                            <Card sx={{ maxWidth: 1200, border: "2px ", borderRadius: "16px", boxShadow: "0px 2px 16px rgba(34, 24, 87, 0.5)", padding: "0em" }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <Button sx={{ padding: "5px" }} ><SearchIcon htmlColor='#1565C0' /></Button>
                                        <TextField color='#1565C0' fullWidth placeholder='Search by Car Model, Brand or Type' inputMode='text' InputProps={{ disableUnderline: true }} sx={{ color: "#1565C0" }} autoFocus={true} variant="standard" />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} style={{ maxWidth: "1200px", display: "flex", justifyContent: "center", paddingTop: "1em" }}>
                        <Grid item lg={4} xs={4}>
                            <Grid container spacing={1} sx={{ border: "2px solid", borderColor: "primary.dark", borderRadius: "16px", boxShadow: "0px 2px 8px rgba(34, 24, 87, 0.5)", display: "flex", flexDirection: "column", marginTop: "1em", padding: "0" }}>
                                <Typography sx={{ backgroundColor: "primary.dark", borderTopLeftRadius: "16px", borderTopRightRadius: "16px", color: "white", width: "100%", textAlign: "center", padding: "8px" }} fontWeight={600}>Browse Topics</Typography>
                                <Grid item lg={12} xs={12}>
                                    <Card>
                                        <CardContent >
                                            <Typography variant="body1" fontWeight="600" color="primary.dark" >
                                                Car Talk
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <Card>
                                        <CardContent >
                                            <Typography variant="body1" fontWeight="600" color="primary.dark" >
                                                Car Talk
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8} xs={8} justifyContent="center">
                            {this.dummyItems()}
                        </Grid>

                    </Grid>

                </Grid>
                <Footer />
            </div>
        )
    }
}

export default Community