import React from 'react';
import './style.css';
import car from '../../media/images/car.jpeg';
import ModelRecognition from '../../components/ModelRecognition';
import Footer from './Components/Footer';
import { ArrowHandLeft, ArrowHandRight, Start } from './Components/Icon';
import Navbar from './Components/Navbar';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import axios from 'axios';

function Spechsheet() {
    // const [data, setData] = useState([]);
    // const fetchData = async () => {
    //     const response = await fetch('https://carclues.onrender.com/car/random/');
    //     const body = await response.json();

    //     if (response.status !== 200) {
    //         throw Error(body.message)
    //     }
    //     return body;
    // };

    // useEffect(() => {
    //     fetchData().then(res => setData(res.data))
    //     setSwiperLimit(window.innerWidth >= 800 ? 3 : window.innerWidth >= 600 ? 2 : 1);
    //     window.addEventListener('resize', () => { setSwiperLimit(window.innerWidth >= 800 ? 3 : window.innerWidth >= 600 ? 2 : 1) })
    // }, [])
    const { carName } = useParams()
    const [car, setCar] = useState()

    const checkLogin = async () => {
        try {
            const token = localStorage.getItem('carc-sec-token');
            if (token) {
                let formdata = new FormData();
                formdata.append("refresh", token);
                let bodyContent = formdata;
                let reqOptions = {
                    url: "http://127.0.0.1:8000/api/token/refresh/",
                    method: "POST",
                    data: bodyContent,
                }
                let response = await axios.request(reqOptions);
                return response;
            }
            else {
                console.log("Not logged in")
            }
        } catch (error) {
        }
    };


    const fetchData = async () => {
        try {
            checkLogin().then(async res => {
                try {
                    if(res.data !== undefined) {
                        const response = await fetch('http://127.0.0.1:8000/car/specific-logged/' + carName, {
                            headers: {
                                'Authorization': `Bearer ${res.data.access}`
                            }
                            })
                        const body = await response.json();
    
                        if (response.status !== 200) {
                            throw Error(body.message)
                        }
                        setCar(body.data)
                        console.log("Recommended")
                    }
                }
                catch {
                    const response = await fetch('http://127.0.0.1:8000/car/specific/' + carName);
                    const body = await response.json();

                    if (response.status !== 200) {
                        throw Error(body.message)
                    }
                    setCar(body.data)
                    console.log("Not Recommended")
                }
            })
        }
        catch {

        }
    };

    // const fetchData = async () => {
    //     try {
    //         const response = await fetch('http://127.0.0.1:8000/car/specific/' + carName);
    //         const body = await response.json();
    
    //         if (response.status !== 200) {
    //             throw Error(body.message)
    //         }
    //         return body;
    //     }
    //     catch {

    //     }
    // };
    useEffect(() => {
        fetchData()
    }, [{}, {}])
    // useEffect(() => {
    //     car ? renderCar() 
    // }, {})
    // const renderCar = () => {
    //     return 
    // }

    return (
        <>
            <Navbar />
            {/* {renderCar()} */}
            <section className='main mt-10 px-4' style={{ margin: "auto", marginTop: 25, maxWidth: 1300, display: "flex", flexDirection: "column" }}>
            <div className='flex flex-col'>
                {console.log(carName)}
                <div className='text-3xl font-bold text-blue-700'>{car ? car[0]['car_make'] + ' ' + car[0]['car_model'] + ' ' + car[0]['car_year_manufacture'] : "Loading..."}</div>
                <div className='w-full p-5 bg-gray-100 border-2 border-gray-300'>
                    <div className='flex flex-col-reverse md:flex-row '>
                        <div className='flex flex-col gap-4 w-full md:w-1/2'>
                            <div className='text-2xl py-2 font-bold text-green-700'>{car ? `PKR ${car[0]['car_price']}`: 'loading'}</div>
                            <div className='text-xl py-2 flex flex-row items-center gap-2'>
                                <div className='font-semibold'>Review</div>
                                <Start fill='#FFB400' />
                                <Start fill='#FFB400' />
                                <Start fill='#FFB400' />
                                <Start />
                                <Start />
                                (165)
                            </div>
                            <div className='flex flex-row gap-2'>
                                <div className='font-semibold'>Engine</div>
                                <div>{car ? `${car[0]['car_engine_cc']} CC` : "loading"}</div>
                            </div>
                            <div className='flex flex-row gap-2'>
                                <div className='font-semibold'>Transmission</div>
                                <div>{car ? car[0]['car_transmission'].charAt(0).toUpperCase() + car[0]['car_transmission'].toLowerCase().slice(1) : 'loading'}</div>
                            </div>
                            <div className='flex flex-row gap-2'>
                                <div className='font-semibold'>Fuel Type</div>
                                <div>{car ? car[0]['car_fuel_type'].charAt(0).toUpperCase() + car[0]['car_fuel_type'].toLowerCase().slice(1) : 'loading'}</div>
                            </div>
                        </div>
                        {/* <div className='flex flex-col justify-center w-full md:w-1/2'> */}
                        <div className='flex mobileCenter w-full md:w-1/2' style={{ justifyContent: "right" }}>
                            <img className='' style={{ maxHeight: 280, maxWidth: 340 }} src={car ? car[0]['car_img'] : ''} alt="..." />
                            {/* <div className='flex w-full justify-between'>
                            <ArrowHandLeft/>
                            <ArrowHandRight/>
                        </div> */}
                            {/* <div className='flex flex-row overflow-y-hidden gap-2 scroll'>
                            {[1,2,3,4,5,6,7,8,9,10].map(i => (
                                <img className='w-24 h-24' src={car} alt="..." key={i}/>
                            ))}
                        </div> */}
                        </div>
                    </div>
                </div>
                {/* <div className='w-full flex pt-5'> */}
                    {/* <div className='w-1/2'> */}
                        {/* <div className='text-2xl font-bold'>Variants</div> */}
                        {/* <table className='table border-2 bg-gray-100'> */}
                            {/* <tr className='border-2'> */}
                                {/* <td className='px-3 py-2'>Honda Civic Standard</td> */}
                            {/* </tr> */}
                            {/* <tr className='border-2'> */}
                                {/* <td className='px-3 py-2'>Honda Civic Oriel</td> */}
                            {/* </tr> */}
                            {/* <tr className='border-2'> */}
                                {/* <td className='px-3 py-2'>Honda Civic RS Turbo</td> */}
                            {/* </tr> */}
                        {/* </table> */}
                    {/* </div> */}
                    {/* <div className='w-1/2'>
                        <div className='text-2xl font-bold'>Price</div>
                        <table className='table border-2 bg-gray-100 '>
                            <tr className='border-2'>
                                <td className='px-3 py-2 text-green-700'>PKR 4.813.912</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 text-green-700'>PKR 5.103.418</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 text-green-700'>PKR 6.478.141</td>
                            </tr>
                        </table>
                    </div> */}
                {/* </div> */}
                <div className='w-full flex pt-5'>
                    <div className='w-full'>
                        <div className='text-2xl font-bold'>Specifications</div>
                        <table className='table border-2 bg-gray-100'>
                            {/* <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6 '>Price</td>
                                <td className='px-3 py-2 text-green-700'>PKR 48.1-64.7</td>
                            </tr> */}
                            {/* <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Engine</td>
                                <td className='px-3 py-2'>1500CC</td>
                            </tr> */}
                            <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Type</td>
                                <td className='px-3 py-2'>{car ? car[0]['car_type'] : 'loading'}</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Fuel</td>
                                <td className='px-3 py-2'>{car ? car[0]['car_fuel_type'].charAt(0).toUpperCase() + car[0]['car_fuel_type'].toLowerCase().slice(1) : 'loading'}</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Transmision</td>
                                <td className='px-3 py-2'>{car ? car[0]['car_transmission'].charAt(0).toUpperCase() + car[0]['car_transmission'].toLowerCase().slice(1) : 'loading'}</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Mileage</td>
                                <td className='px-3 py-2'>{car ? `${car[1]['car_mileage_min']}${car[1]['car_mileage_max'] > 0 ? " - " + car[1]['car_mileage_max'] + ' KM/L' : " KM/L"}` : 'loading'}</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Top Speed </td>
                                <td className='px-3 py-2'>{car ? car[1]['car_top_speed'] + ' KM/H' : 'loading'}</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Horse Power</td>
                                <td className='px-3 py-2'>{car ? `${car[1]['car_hp_min']}${car[1]['car_hp_max'] > 0 ? " - " + car[1]['car_hp_max'] + ' HP' : " HP"}` : 'loading'}</td>
                            </tr>
                            <tr className='border-2'>
                                <td className='px-3 py-2 border-2 w-1/6'>Torque</td>
                                <td className='px-3 py-2'>{car ? `${car[1]['car_torque_min']}${car[1]['car_torque_max'] > 0 ? " - " + car[1]['car_torque_max'] + ' Nm' : " Nm"}` : 'loading'}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

        </section>
            <Footer />
            <ModelRecognition />
        </>
    )
}

export default Spechsheet
