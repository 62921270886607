import React, { useEffect, useState } from 'react';
import './style.css';
import car from '../../media/images/car.jpeg';
import Navbar from './Components/Navbar';
import ModelRecognition from '../../components/ModelRecognition';
import Footer from './Components/Footer';
import { Check, Cross, Plus } from './Components/Icon';
import { TextField, Typography } from '@mui/material';

function Comparison() {
    const [changes, setChanges] = useState(false)
    const [car1, setCar1] = useState()
    const [car2, setCar2] = useState()
    const [car1Good, setCar1Good] = useState(false)
    const change = () => {
        // setChanges(!changes)
    }

    const fetchData = async (search) => {
        try {
            const response = await fetch('https://api.carclues.pk/car/specific/' + search);
            const body = await response.json();
            console.log(body.data)
            if (response.status !== 200) {
                throw Error(body.message)
            }
            return body;
        }
        catch {
            
        }
    };

    const checkCarInput = (e) => {
        if (e.key === "Enter") {
            if (e.target.name === 'car1') {
                // fetchData(e.target.value).then(res => { setCar1(res.data);})
                fetchData(e.target.value).then(res => {setCar1(res.data)})
            }
            if (e.target.name === 'car2') {
                fetchData(e.target.value).then(res => { setCar2(res.data); })
            }
        }
    }

    const isCar1Good = () => {
        var car1Score = 0
        var car2Score = 0
        if (car1 && car2) {
            car1[0]['car_engine_cc'] > car2[0]['car_engine_cc'] ? car1Score++ : car2Score++
            car1[0]['car_price'] < car2[0]['car_price'] ? car1Score++ : car2Score++
            car1[1]['car_fuel_capacity'] > car2[1]['car_fuel_capacity'] ? car1Score++ : car2Score++
            car1[1]['car_mileage_min'] > car2[1]['car_mileage_min'] ? car1Score++ : car2Score++
            car1[1]['car_top_speed'] > car2[1]['car_top_speed'] ? car1Score++ : car2Score++
            car1[1]['car_hp_min'] > car2[1]['car_hp_min'] ? car1Score++ : car2Score++
            car1[1]['car_torque_min'] > car2[1]['car_torque_min'] ? car1Score++ : car2Score++
            if (car1Score > car2Score) {
                setCar1Good(true)
                return
            }
            setCar1Good(false)
            return
        }
        setCar1Good(true)
    }

    useEffect(() => {
        if (car1 && car2) {
            isCar1Good()
        }
    }
    )

    return (
        <>
            <Navbar />
            <section className='main mt-10 px-4' style={{ margin: "auto", marginTop: 42, maxWidth: 1300, display: "flex", flexDirection: "column" }}>
                <div className='flex flex-col'>
                    <div className='text-3xl font-bold text-blue-700'>Car Comparison</div>
                    <div className='text-2xl'>look at specifications of two cars side by side. Determine which one caters</div>
                    <div className='text-2xl'> to you the best</div>

                    {/* <div className='text-2xl font-bold pt-2' onClick={change}>Select two Cars:</div> */}
                    {changes ? (
                        <div className='flex flex-row w-full gap-2 pt-4'>
                            <div className='border-2 border-blue-700 py-36 w-1/2 items-center justify-center flex' onClick={change}>
                                <div className='p-2 bg-blue-200 w-20 h-20 items-center flex justify-center' onClick={change}>
                                    <Plus />
                                </div>
                            </div>
                            <div className='border-2 border-blue-700 py-36 w-1/2 items-center justify-center flex' onClick={change}>
                                <div className='p-2 bg-blue-200 w-20 h-20 items-center flex justify-center' onClick={change}>
                                    <Plus />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-row w-full gap-2 pt-4' onClick={change}>
                            <div className='card-custom'>
                                <div className='bg-blue-600 text-white px-2 pt-2 rounded-t text-xl font-bold'>
                                    <p className='pb-2'>Compare By</p>
                                </div>
                                <div className='px-2 pt-0'>
                                    <div className='px-2'>
                                        {['Fuel Capacity', 'Engine Power', 'Mileage', 'Top Speed', 'Horse Power', 'Torque'].map((i, index) => (
                                            <div className="flex  mb-2" key={index}>
                                                <input id="default-checkbox" type="checkbox" class="engineType" value={i} name='engineType' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-600">{i}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <table className='w-full'>
                                <tr>
                                    <td>
                                        <Typography variant='body1' fontWeight={700} >Select Two Cars</Typography>
                                    </td>
                                    <td style={{ display: "flex", justifyContent: "center" }} colSpan={3}>
                                        <TextField variant='outlined' placeholder='Car 1' name='car1' style={{ width: "95%" }} onKeyDown={(e) => { checkCarInput(e) }} />
                                    </td>
                                    <td >
                                        <TextField variant='outlined' placeholder='Car 2' name='car2' style={{ width: "95%" }} onKeyDown={(e) => { checkCarInput(e) }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <img style={{ maxWidth: "368px", maxHeight: "295px" }} src={car1 ? car1[0]['car_img'] : ''} alt="..." />
                                    </td>
                                    <td>
                                        <img style={{ maxWidth: "368px", maxHeight: "295px" }} src={car2 ? car2[0]['car_img'] : ''} alt="..." />
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Name</td>
                                    <td className='px-3 py-2 border-2'>{car1 ? car1[0]['car_make'] + ' ' + car1[0]['car_model'] + ' ' + car1[0]['car_year_manufacture'] : 'Select a car'}</td>
                                    <td className='px-3 py-2 border-2'>{car2 ? car2[0]['car_make'] + ' ' + car2[0]['car_model'] + ' ' + car2[0]['car_year_manufacture'] : 'Select a car'}</td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold font-color green'>Price</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? 'PKR ' + car1[0]['car_price'] : 'Select a car'}</div>
                                            {car1 && car2 ? car1[0]['car_price'] < car2[0]['car_price'] ? <Check /> : <Cross /> : ''}
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? 'PKR ' + car2[0]['car_price'] : 'Select a car'}</div>
                                            {car1 && car2 ? car1[0]['car_price'] < car2[0]['car_price'] ? <Cross /> : <Check /> : ''}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Engine</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? car1[0]['car_engine_cc'] + ' CC' : 'Select a car'}</div>
                                            {car1 && car2 ? car1[0]['car_engine_cc'] > car2[0]['car_engine_cc'] ? <Check /> : <Cross /> : ''}
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? car2[0]['car_engine_cc'] + ' CC' : 'Select a car'}</div>
                                            {car1 && car2 ? car1[0]['car_engine_cc'] > car2[0]['car_engine_cc'] ? <Cross /> : <Check /> : ''}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Type</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? car1[0]['car_type'].charAt(0).toUpperCase() + car1[0]['car_type'].toLowerCase().slice(1) : 'Select a car'}</div>
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? car2[0]['car_type'].charAt(0).toUpperCase() + car2[0]['car_type'].toLowerCase().slice(1) : 'Select a car'}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Fuel</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? car1[0]['car_fuel_type'].charAt(0).toUpperCase() + car1[0]['car_fuel_type'].toLowerCase().slice(1) : 'Select a car'}</div>
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? car2[0]['car_fuel_type'].charAt(0).toUpperCase() + car2[0]['car_fuel_type'].toLowerCase().slice(1) : 'Select a car'}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Transmision</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? car1[0]['car_transmission'].charAt(0).toUpperCase() + car1[0]['car_transmission'].toLowerCase().slice(1) : 'Select a car'}</div>
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? car2[0]['car_transmission'].charAt(0).toUpperCase() + car2[0]['car_transmission'].toLowerCase().slice(1) : 'Select a car'}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Mileage</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? `${car1[1]['car_mileage_min']}${car1[1]['car_mileage_max'] > 0 ? " - " + car1[1]['car_mileage_max'] + ' KM/L' : " KM/L"}` : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_mileage_min'] == car2[1]['car_mileage_min'] ? <Check /> : car1[1]['car_mileage_min'] > car2[1]['car_mileage_min'] ? <Check /> : <Cross /> : ''}
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? `${car2[1]['car_mileage_min']}${car2[1]['car_mileage_max'] > 0 ? " - " + car2[1]['car_mileage_max'] + ' KM/L' : " KM/L"}` : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_mileage_min'] == car2[1]['car_mileage_min'] ? <Check /> : car1[1]['car_mileage_min'] > car2[1]['car_mileage_min'] ? <Cross /> : <Check /> : ''}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Fuel Capacity</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? car1[1]['car_fuel_capacity'] + ' L' : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_fuel_capacity'] == car2[1]['car_fuel_capacity'] ? <Check /> : car1[1]['car_fuel_capacity'] > car2[1]['car_fuel_capacity'] ? <Check /> : <Cross /> : ''}
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? car2[1]['car_fuel_capacity'] + ' L' : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_fuel_capacity'] == car2[1]['car_fuel_capacity'] ? <Check /> : car1[1]['car_fuel_capacity'] > car2[1]['car_fuel_capacity'] ? <Cross /> : <Check /> : ''}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Top Speed </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? car1[1]['car_top_speed'] + ' KM/H' : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_top_speed'] == car2[1]['car_top_speed'] ? <Check /> : car1[1]['car_top_speed'] > car2[1]['car_top_speed'] ? <Check /> : <Cross /> : ''}
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? car2[1]['car_top_speed'] + ' KM/H' : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_top_speed'] == car2[1]['car_top_speed'] ? <Check /> : car1[1]['car_top_speed'] > car2[1]['car_top_speed'] ? <Cross /> : <Check /> : ''}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Horse Power</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? `${car1[1]['car_hp_min']}${car1[1]['car_hp_max'] > 0 ? " - " + car1[1]['car_hp_max'] + ' HP' : " HP"}` : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_hp_min'] == car2[1]['car_hp_min'] ? <Check /> : car1[1]['car_hp_min'] > car2[1]['car_hp_min'] ? <Check /> : <Cross /> : ''}
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? `${car2[1]['car_hp_min']}${car2[1]['car_hp_max'] > 0 ? " - " + car2[1]['car_hp_max'] + ' HP' : " HP"}` : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_hp_min'] == car2[1]['car_hp_min'] ? <Check /> : car1[1]['car_hp_min'] > car2[1]['car_hp_min'] ? <Cross /> : <Check /> : ''}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Torque</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car1 ? `${car1[1]['car_torque_min']}${car1[1]['car_torque_max'] > 0 ? " - " + car1[1]['car_torque_max'] + ' Nm' : " Nm"}` : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_torque_min'] == car2[1]['car_hp_min'] ? <Check /> : car1[1]['car_torque_min'] > car2[1]['car_torque_min'] ? <Check /> : <Cross /> : ''}
                                        </div>
                                    </td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-between'>
                                            <div>{car2 ? `${car2[1]['car_torque_min']}${car2[1]['car_torque_max'] > 0 ? " - " + car2[1]['car_torque_max'] + ' Nm' : " Nm"}` : 'Select a car'}</div>
                                            {car1 && car2 ? car1[1]['car_torque_min'] == car2[1]['car_hp_min'] ? <Check /> : car1[1]['car_torque_min'] > car2[1]['car_torque_min'] ? <Cross /> : <Check /> : ''}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='border-2'>
                                    <td className='px-3 py-2 border-2 w-1/6 font-bold'>Over All Value</td>
                                    <td className='px-3 py-2 border-2'>
                                        <div className='w-full flex justify-center'>{car1Good ? <Check /> : <Cross />} </div>
                                    </td>
                                    <td className='px-3 py-2 mx-auto border-2 text-center items-center justify-center'>
                                        <div className='w-full flex justify-center'>{car1Good ? <Cross /> : <Check />}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    )}
                </div>
            </section>
            <Footer />
            <ModelRecognition />
        </>
    )
}

export default Comparison