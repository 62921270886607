import React, { useState } from 'react';
import './style.css';
import Navbar from './Components/Navbar';
import ModelRecognition from '../../components/ModelRecognition';
import Footer from './Components/Footer';
import { Container } from 'react-bootstrap';
import { ArrowDown, ArrowRight, Warning } from './Components/Icon';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import SearchCard from './Components/searchCard';
import { useNavigate } from "react-router-dom";


function SearchResults() {
    var navigate = useNavigate(); 
    const [state, setState] = useState(true)
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState({
        bodyType: '',
        engineType: '',
        priceMin: '',
        priceMax: '',
        transmission: '',
        engineMin: '',
        engineMax: '',
        buildYearMin: '',
        buildYearMax: ''
    });
    const { search } = useParams()

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilter((prevFilter) => ({
            ...prevFilter,
            [name]: value,
        }
        ));
    };

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.carclues.pk/car/search/' + search);
            const body = await response.json();
    
            if (response.status !== 200) {
                throw Error(body.message)
            }
            return body;
        }
        catch{
            
        }
    };

    const FilterResults = () => {
        const filteredItems = data.filter((item) => {
            if (filter.bodyType && filter.bodyType!=='Any' && item.car_type.toLowerCase() !== filter.bodyType.toLowerCase()) {
                return false;
            }
            if (filter.engineType && filter.engineType!=='Any' && item.car_fuel_type.toLowerCase() !== filter.engineType.toLowerCase()) {
                return false;
            }
            if(filter.priceMin && filter.priceMin < parseFloat(item.car_price)) {
                return false
            }
            if(filter.priceMax && filter.priceMax > parseFloat(item.car_price)) {
                return false
            }
            if(filter.priceMin && filter.priceMax) {
                if(!(item.car_price >= filter.priceMin && item.car_price <= filter.priceMax)) {
                    return false
                }
            }
            if(filter.transmission && filter.transmission !== 'Any') {
                if(!item.car_transmission.includes(filter.transmission.toLowerCase())) {
                    return false
                }
            }
            if(filter.engineMin && !filter.engineMax && filter.engineMin < item.car_engine_cc) {
                return false;
            }
            if(filter.engineMax && !filter.engineMin && filter.engineMax > item.car_engine_cc) {
                return false
            }
            if(filter.engineMin && filter.engineMax) {
                if(!(item.car_engine_cc >= filter.engineMin && item.car_engine_cc <= filter.engineMax)) {
                    return false
                }
            }
            if(filter.buildYearMin && !filter.buildYearMax && filter.buildYearMin < item.car_year_manufacture) {
                return false;
            }
            if(filter.buildYearMax && !filter.buildYearMin && filter.buildYearMax > item.car_year_manufacture) {
                return false
            }
            if(filter.buildYearMin && filter.buildYearMax) {
                if(!(item.car_year_manufacture >= filter.buildYearMin && item.car_year_manufacture <= filter.buildYearMax)) {
                    return false
                }
            }
            return true;
        });
        console.log(filter)
        setFilteredData(filteredItems)
    }

    useEffect(() => {
        fetchData().then(res => { setData(res.data); setFilteredData(res.data) })
    }, [])
    useEffect(() => {
        FilterResults();
      }, [data, filter]);
    return (
        <>
            <Navbar />
            <section className='main' style={{ marginTop: '20px' }}>
                <Container>
                    <p className='text-xl'>These are the result we could find for you query</p>
                    <p className='text-2xl font-bold'>Here are "{search}" in Pakistan :</p>
                </Container>
                <Container >
                    <div className='flex flex-row gap-2 pt-2'>
                        <div className='basis-2/6'>
                            <div className='card-custom'>
                                <div className='bg-blue-600 text-white px-2 pt-2 rounded-t text-xl font-bold'>
                                    <p className='pb-2'>Filter List</p>
                                </div>
                                <div className='px-4 pt-0'>
                                    <p className='text-blue-600 underline text-base'>Clear all filter</p>
                                </div>
                                <div className='px-4 pt-0'>
                                    <div className='flex justify-between w-full text-blue-800'>
                                        <p>Engine Type</p>
                                        <ArrowDown />
                                    </div>
                                    <div className='px-2'>
                                        {['Petrol', 'Hybrid', 'CNG', 'Diesel', 'Any'].map((i, index) => (
                                            <div className="flex items-center mb-2" key={index}>
                                                <input id="default-checkbox" type="radio" class="engineType" value={i} name='engineType' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleFilterChange} />
                                                <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-600">{i}</label>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='flex justify-between w-full text-blue-800 pt-4'>
                                        <p>Body Type</p>
                                        <ArrowDown />
                                    </div>
                                    <div className='px-2'>
                                        {['Sedan', 'Hatchback', 'Crossover', 'SUV', 'Any'].map((i, index) => (
                                            <div className="flex items-center mb-2" key={index}>
                                                <input id="default-checkbox" type="radio" name='bodyType' value={i} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleFilterChange} />
                                                <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-600">{i}</label>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='flex justify-between w-full text-blue-800 pt-4'>
                                        <p>Price</p>
                                        <ArrowDown />
                                    </div>
                                    <div className='px-2'>
                                        <div className='flex flex-row justify-between gap-2'>
                                            <input placeholder='from' type='number' name='priceMin' className='input w-1/3' onChange={handleFilterChange} />
                                            <input placeholder='to' type='number' name='priceMax' className='input w-1/3' onChange={handleFilterChange} />
                                            {/* <div className='flex items-center bg-blue-700 text-white rounded w-1/3 text-sm'>
                                                <div className='px-2'>Go</div>
                                                <ArrowRight />
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className='flex justify-between w-full text-blue-800 pt-4'>
                                        <p>Transmision</p>
                                        <ArrowDown />
                                    </div>
                                    <div className='px-2'>
                                        {['Automatic', 'Manual', 'Any'].map((i, index) => (
                                            <div className="flex items-center mb-2" key={index}>
                                                <input id="default-checkbox" type="radio" name='transmission' value={i} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleFilterChange} />
                                                <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-600">{i}</label>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='flex justify-between w-full text-blue-800 pt-4'>
                                        <p>Engine (CC)</p>
                                        <ArrowDown />
                                    </div>
                                    <div className='px-2'>
                                        <div className='flex flex-row justify-between gap-2'>
                                            <input placeholder='from' type='number' name='engineMin' className='input w-1/3' onChange={handleFilterChange} />
                                            <input placeholder='to' type='number' name='engineMax' className='input w-1/3' onChange={handleFilterChange} />
                                            {/* <div className='flex items-center bg-blue-700 text-white rounded w-1/3 text-sm'>
                                                <div className='px-2'>Go</div>
                                                <ArrowRight />
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className='flex justify-between w-full text-blue-800 pt-4'>
                                        <p>Build Year</p>
                                        <ArrowDown />
                                    </div>
                                    <div className='px-2 pb-4'>
                                        <div className='flex flex-row justify-between gap-2'>
                                            <input placeholder='from' type='text' name='buildYearMin' className='input w-1/3' onChange={handleFilterChange} />
                                            <input placeholder='to' type='text' name='buildYearMax' className='input w-1/3' onChange={handleFilterChange} />
                                            {/* <div className='flex items-center bg-blue-700 text-white rounded w-1/3 text-sm'>
                                                <div className='px-2'>Go</div>
                                                <ArrowRight />
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='basis-4/6'>
                            {state ? (
                                <>
                                    {filteredData.map(
                                        ({ car_img, car_make, car_model, car_year_manufacture, car_transmission, car_fuel_type, car_engine_cc, car_price }, index) =>
                                            <div className='border-blue-600 border-2 rounded mt-2' key={index}>
                                                <div className='flex flex-row'>
                                                    <div className='basis-2/5'>
                                                        <img className="px-4" src={car_img} alt={car_make + ' ' + car_model} />
                                                    </div>
                                                    <div className='basis-3/5 flex flex-col h-max'>
                                                        <div className='flex flex-col md:flex-row px-2 pt-4 font-bold justify-between text-2xl'>
                                                            <div className='text-blue-600'>{car_make + ' ' + car_model}</div>
                                                            <div className='text-green-700 md:text-end'>PKR {car_price}</div>
                                                        </div>
                                                        <div className='sm:flex items-center justify-between hidden px-2 py-4 gap-2 text-base font-bold' style={{ textAlign: "center" }}>
                                                            <div>{car_year_manufacture}</div>
                                                            <div className='dot' />
                                                            <div>{car_transmission}</div>
                                                            <div className='dot' />
                                                            <div>{car_fuel_type}</div>
                                                            <div className='dot' />
                                                            <div>{car_engine_cc} (CC) </div>
                                                        </div>
                                                        <div className='flex justify-end px-2 pt-4 pb-2'>
                                                            <div className='btn btn-primary' onClick={() => {navigate('/spec/' + `${car_make} ${car_model} ${car_year_manufacture}`)}}>See Detail</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            // <SearchCard carImg={car_img} carName={car_make + ' ' + car_model} carPrice={car_price} carYear={car_year_manufacture} carTransmission={car_transmission} carFuel={car_fuel_type} carEngine={car_engine_cc} />
                                    )}

                                </>
                            ) : (
                                <div className='flex flex-row items-center gap-3 text-red-700 text-xl font-bold'>
                                    <Warning />
                                    <div className='flex flex-col'>
                                        <div>
                                            Could not find what you were looking for.
                                        </div>
                                        <div>
                                            Please try again!
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </section>
            <Footer />
            <ModelRecognition />
        </>
    )
}

export default SearchResults