import React from 'react';
import { HiCamera } from 'react-icons/hi'
import { useState } from 'react';
import { Typography, Button, TextField } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { display } from '@mui/system';
import { ChevronRight } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useEffect } from 'react';

function ModelRecognition() {
    const [enableRecognition, setEnableRecognition] = useState(false)
    const [enableUpload, setEnableUpload] = useState(true)
    const [enablePreview, setEnablePreview] = useState(false)
    const [file, setFile] = useState(null);
    const onDrop = useCallback((acceptedFiles) => {
        setEnablePreview(true);
        setEnableUpload(false);
        setFile(Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        }));
    }, []);

    const getCSRFToken = async () => {
        const response = await axios.get('http://127.0.0.1:8000/api/csrf/');
        // axios.defaults.headers.post['X-CSRFToken'] = response.data.csrfToken;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await axios.get('http://127.0.0.1:8000/api/csrf/');
        const formData = new FormData();
        formData.append('image', file);
        formData.append('Authorization', 'Token ' + response.data.csrfToken)
        axios
          .post('http://127.0.0.1:8000/car/get-classification/', formData)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

    useEffect(() => {
        try {
            getCSRFToken()
        }
        catch {
        }
    }, [])

    return (
        <>
            <button onClick={() => { setEnableRecognition(true) }} className='btn btn-primary model-recognition d-flex items-center gap-2'>
                Model Recognition <HiCamera />
            </button>

            {/* Model Recognition  */}
            <div className="modal" style={{ display: enableRecognition ? 'flex' : 'none' }}>
                <div className='modal-content' style={{ display: enableUpload ? "flex" : "none" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant='h5' fontWeight={600} marginTop={0} color='primary'>Car Model Recognition</Typography>
                        <Button onClick={() => {setEnableRecognition(false)}}><CloseIcon htmlColor='red' /></Button>
                    </div>
                    <br />
                    <Typography variant='body2'>Drop a Image of a car or do so by pressing the “Import” Button and our Car Model Recognition will show you the desired results</Typography>
                    <br />
                    <div id='action-buttons' className="image-modal" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Button variant="contained" type='submit' style={{ fontWeight: 600 }} color='primary' >Import</Button>
                                <Typography style={{ margin: 7 }}> or Drop File </Typography>
                            </div>
                        )}
                    </div>
                </div>

                <div className='modal-content' style={{ display: enablePreview ? "flex" : "none" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant='h5' fontWeight={600} marginTop={0} color='primary'>Car Model Recognition</Typography>
                        <Button><CloseIcon htmlColor='red' /></Button>
                    </div>
                    <br />
                    <Typography variant='body2'>Drop a Image of a car or do so by pressing the “Import” Button and our Car Model Recognition will show you the desired results</Typography>
                    <br />
                    <div id='action-buttons'>
                        {file && (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ maxWidth: "30%", marginRight: "1em" }}>
                                    <img src={file.preview} alt={file.name} style={{ display: "inline" }} />
                                    <br />
                                    <p style={{ display: "inline" }}>{file.name} </p>
                                </div>
                                <div>
                                    <Typography variant='body1' fontWeight={600}>Run Car Model Recognition on selected image.</Typography>
                                    <Typography variant='body2' >Hit cancel to go back to the original screen and reupload an image.</Typography>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Button variant='outlined' style={{ marginRight: "1em" }} >Cancel</Button>
                                        <Button variant='contained' type='submit' onClick={handleSubmit}>Go <ChevronRight /> </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>

    )
}

export default ModelRecognition