import React, { useState, useEffect } from 'react';
import CardFlex from './Components/CardFlex';
import Card from './Components/Card';
import ModelRecognition from '../../components/ModelRecognition';
import Footer from './Components/Footer';
import HeaderImage from '../../media/images/header-image.png';
import axios from 'axios';
// import Swiper core and required modules
import { Pagination, A11y, Grid } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import Navbar from './Components/Navbar';

export default function Home() {
    const [data, setData] = useState([]);
    const [swiperLimit, setSwiperLimit] = useState([]);
    const checkLogin = async () => {
        try {
            const token = localStorage.getItem('carc-sec-token');
            if (token) {
                let formdata = new FormData();
                formdata.append("refresh", token);
                let bodyContent = formdata;
                let reqOptions = {
                    url: "http://127.0.0.1:8000/api/token/refresh/",
                    method: "POST",
                    data: bodyContent,
                }
                let response = await axios.request(reqOptions);
                return response;
            }
            else {
                console.log("Not logged in")
            }
        } catch (error) {
        }
    };

    const fetchData = async () => {
        try {
            checkLogin().then(async res => {
                try {
                    if(res.data !== undefined) {
                        const response = await fetch('http://127.0.0.1:8000/car/recommend/', {
                            headers: {
                                'Authorization': `Bearer ${res.data.access}`
                            }
                            })
                        const body = await response.json();
                        console.log(body)
                        if (response.status !== 200) {
                            throw Error(body.message)
                        }
                        setData(body.data)
                        console.log("Recommended")
                    }
                }
                catch {
                    const response = await fetch('http://127.0.0.1:8000/car/random/');
                    const body = await response.json();

                    if (response.status !== 200) {
                        throw Error(body.message)
                    }
                    console.log(body)
                    setData(body.data)
                    console.log("Not Recommended")
                }
            })
        }
        catch {

        }
    };

    useEffect(() => {
        try {
            fetchData()
        }
        catch {
        }
        setSwiperLimit(window.innerWidth >= 800 ? 3 : window.innerWidth >= 600 ? 2 : 1);
        window.addEventListener('resize', () => { setSwiperLimit(window.innerWidth >= 800 ? 3 : window.innerWidth >= 600 ? 2 : 1) })
    }, [])

    return (
        <>
            <Navbar />
            <section className='main' style={{ margin: "auto", maxWidth: 1300, display: "flex", flexDirection: "column" }}>
                <div className='page-caption mx-4'>
                    <div className='page-caption-header'>
                        <p className='fw-bold page-caption-text'>Coming Back Soon...</p>
                        <p className='fw-bold page-caption-text'>CarClues provide you with,</p>
                        <p className='page-caption-heading'>
                            Unbiased Information. <br />
                            Community. <br />
                            Comparsions. <br />
                            Model Recognition. <br />
                        </p>
                    </div>
                    <figure className='page-caption-figure'>
                        <img src={HeaderImage} alt=''></img>
                    </figure>
                </div>
                <div className='mx-4 divider-primary' />
                <div className='page-grid'>
                    <p className='mx-4 fw-bold'>Our Recommendations for the cars to look out for,</p>
                    <div className='px-4 listing-grid'>
                        {
                            data.slice(0, 10).map(
                                ({ car_img, car_make, car_model, car_year_manufacture, car_details }) => <CardFlex image={"http://127.0.0.1:8000" + car_img} title={car_make + ' ' + car_model + ' ' + car_year_manufacture} description={car_details.length > 152 ? car_details.substring(0, 152) + '...' : car_details} />
                            )
                        }
                    </div>
                </div>
                <div className='mx-4 divider-primary' />
                <div className='mx-4 filter-listing'>
                    <h5 className='fw-bold'>See All New Cars</h5>
                    <p>Filter by Car Type:</p>
                    <div className='filters'>
                        <a href='./' className='active'>All</a>
                        <a href='./'>SUV</a>
                        <a href='./'>Hybrid and Electic</a>
                        <a href='./'>Small Cars</a>
                        <a href='./'>Family Cars</a>
                        <a href='./'>Hatchbacks</a>
                        <a href='./'>Saloons</a>
                    </div>
                    <div className='filter-carosal'>
                        <Swiper
                            modules={[Pagination, A11y, Grid]}
                            spaceBetween={10}
                            slidesPerView={swiperLimit}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            {
                                data.slice(0, 6).map(
                                    ({ car_img, car_make, car_model, car_year_manufacture, car_description }, index) => (
                                        <SwiperSlide >
                                            <Card image={"http://127.0.0.1:8000" + car_img} title={car_make + ' ' + car_model + ' ' + car_year_manufacture} description={car_description} />
                                        </SwiperSlide>
                                    )
                                )
                            }
                        </Swiper>
                    </div>
                </div>
            </section>
            <Footer />
            <ModelRecognition />
        </>
    )
}
