import ReactStars from 'react-stars'
import React from 'react';
import { useNavigate } from "react-router-dom";

import { Button } from '@mui/material';
function Card({image, title, description}){
    var navigate = useNavigate(); 
    const titles = title.split(' ');
    return (
        <div className="my-2 card border-primary br-2">
            <img className="card-img-top p-2" src={image} alt={title} style={{maxWidth: 340, height: 252, objectFit: "contain"}} />
            <div className="card-body">
                <div className="card-title d-flex justify-content-between">
                    <div className='card-split-title'>
                        <h6 className='text-uppercase'>{titles[0]}</h6>
                        <h3 className='text-uppercase color-primary'>{titles[1]}</h3>
                    </div>
                    <ReactStars
                        className='d-tablet-none'
                        count={5}
                        size={24} 
                        color2={'#ffd700'} />
                </div>
                <p style={{textOverflow: 'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{description}</p>
                <ReactStars
                        className='d-desktop-none d-tablet text-center'
                        count={5}
                        size={24}
                        color2={'#ffd700'} />
                <div className='divider-primary'></div>
                <Button style={{ width: "100%" }} variant='contained' onClick={() => {navigate('/spec/' + title)}}>VIEW DETAILS</Button>
            </div>
        </div>
    )
}

export default Card;
