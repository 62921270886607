import * as React from 'react';
import { Card, Box, Grid, CardContent, CardMedia, Button, Typography } from '@mui/material';
import Circle from './circle';
export default function SearchCard (props) {
    return (
        <Card sx={{ maxWidth: 1200, maxHeight:200, border: "2px solid", borderColor: "primary.dark", borderRadius: "16px", boxShadow: "0px 2px 8px rgba(34, 24, 87, 0.5)", display: "flex", flexDirection: "row", padding: "1em  0 0 1em" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <div style={{display: 'flex', justifyContent:"space-between"}}>
                                <Typography component="div" variant="h7" fontWeight="700" color="primary.dark" width="fit-content">
                                    {props.roomName}
                                </Typography>
                                <Typography component="div" variant="h7" fontWeight="700" color="#1B5E20" width="fit-content">
                                    {props.noOfMembers}
                                </Typography>
                            </div>
                            <br />
                            <Typography variant="body1" color="text.primary" display="flex" justifyContent="space-around">
                                Tag1<Circle />Tag2<Circle />Tag3<Circle />Tag4
                            </Typography>
                            <div  style={{display: 'flex', justifyContent:"space-between"}}>
                                <div></div>
                                <Button sx={{ right:0}} variant='contained'>SEE DETAILS</Button>
                            </div>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}