import React, { Component, setState } from 'react';
import NavBar from '../../../src/views/Home/Components/Navbar';
import { Typography, Grid, CardMedia, Chip, ToggleButton } from "@mui/material";
import Footer from '../../../src/views/Home/Components/Footer';
import SearchBox from './Components/searchBox';
class Explore extends Component {
    dummyChips() {
        var dummies = ['SUV', 'Hybrid and Electric', 'Small Cars', 'Family Cars', 'Hatchbacks', 'Saloons', 'Mehran', 'Civic', 'New Cars']
        return dummies.map(item => (<Chip label={item} variant="outlined" clickable={true} sx={{ borderColor: 'primary.light', margin: '8px 1em 8px 0px', color: 'primary.main', border: '1px solid', fontWeight: 700, padding: '1em' }} />))
    }
 
    render() {   
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <NavBar tabIndex={5} />
                <Grid container spacing={0} style={{ marginTop:"72px", padding: "1em", paddingTop:"10%", backgroundColor: "white", justifyContent: "center" }}>
                    <Grid container spacing={1} style={{ margin: 0, maxWidth: "1200px", paddingTop: "1em", flexDirection: "row", textAlign: "center" }} >
                        <Grid item lg={12} md={12} sm={12} xs={12} justifyContent="center">
                            <Typography fontWeight={700} variant="h4" color="primary">Find Cars in the Pakistani Market</Typography>
                            <Typography  variant="body2" fontWeight={300} fontSize="1.5em">with Thousands of cars, you will find the right one here</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ maxWidth: "1200px", justifyContent: "left", paddingTop: "3em" }}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <SearchBox />
                        </Grid>
                        <Grid item lg={11.85} md={12} sm={12} xs={12}>
                            <hr style={{ border: "1px solid #1976D2", width: "100%", marginTop:"18.5%" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' fontWeight={700}>Popular Searches Include,</Typography>
                            <div style={{ display: "flex", flexWrap: 'wrap' }}>
                                {this.dummyChips()}
                            </div>
                        </Grid>
                        <Grid item lg={11.85} md={12} sm={12} xs={12}>
                            <hr style={{ border: "1px solid #1976D2", width: "100%" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        )
    }
}

export default Explore