import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/style.scss";
import "tw-elements";
import Home from './views/Home/Home';
import SearchResults from "./views/Home/SearchResults";
import Explore from "./views/Home/Explore";
import Spechsheet from "./views/Home/SpechSheet";
import Comparison from "./views/Home/Comparison";
import Community from "./views/Home/Community";
import Room from "./views/Home/Room";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/search/:search' element={<SearchResults />} />
          <Route path='/explore/' element={<Explore />} />
          <Route path='/compare/' element={<Comparison />} />
          <Route path='/spec/:carName' element={<Spechsheet />} />
          <Route path='/community/' element={<Community />} />
          <Route path='/community/room/:roomName' element={<Room />} />
        </Routes>
      </BrowserRouter>
  </React.StrictMode>

);
