import React from 'react';
import { useNavigate } from "react-router-dom";

function CardFlex({image, title, description}){
    var navigate = useNavigate(); 
    return (
        <div className="my-2 card d-flex flex-row border-primary br-1">
            <img className="card-img m-2" src={image} alt={title} />
            <div className="card-body">
                <h3 className="card-title">{title}</h3>
                <div className='divider-primary'></div>
                <p className="card-text">{description}</p>
                <button className="btn btn-primary" onClick={() => {navigate('/spec/' + title)}}>SEE DETAILS</button>
            </div>
        </div>
    )
}

export default CardFlex; 