import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FaUserCircle, FaSearch } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { GiHamburgerMenu } from 'react-icons/gi';
import background from '../../../media/images/navbar.svg';
import { Button } from '@mui/material';
import { FormControlLabel, TextField, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
function Navbar() {
    const [mobileMenu, setMobileMenu] = useState(false);
    const [enableLogin, SetEnableLogin] = useState(false)
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleMobileMenu = () => setMobileMenu(!mobileMenu);
    var navigate = useNavigate();
    let hamburger;
    if (mobileMenu) {
        hamburger = <RiCloseLine style={{ fontSize: '2rem', color: '#fff' }} onClick={handleMobileMenu} />;
    } else {
        hamburger = <GiHamburgerMenu style={{ fontSize: '2rem', color: '#fff' }} onClick={handleMobileMenu} />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let formdata = new FormData();
            formdata.append("email", username);
            formdata.append("password", password);
            let bodyContent = formdata;
            let reqOptions = {
                url: "http://127.0.0.1:8000/api/token/",
                method: "POST",
                data: bodyContent,
            }
            let response = await axios.request(reqOptions);
            if (response.status == 401) {
            }
            else {
                localStorage.setItem('carc-sec-token', response.data.refresh);
                window.location.reload()
            }
        } catch {
        }
    };

    const checkLogin = async () => {
        try {

            const token = localStorage.getItem('carc-sec-token');
            if (token) {
                let formdata = new FormData();
                formdata.append("refresh", token);
                let bodyContent = formdata;
                let reqOptions = {
                    url: "https://127.0.0.1:8000/api/token/refresh/",
                    method: "POST",
                    data: bodyContent,
                }
                let response = await axios.request(reqOptions);
                setIsLoggedIn(true);
                SetEnableLogin(false)
            }
            else {
                SetEnableLogin(true)
            }
        } catch (error) {
            SetEnableLogin(true)
        }
    };

    return (
        <>
            <section className="navbar" style={{ backgroundImage: `url(${background})` }}>
                <div className='hamburger'>
                    {hamburger}
                </div>
                <a className='navbar-brand' href='#'>
                    CC
                </a>
                <div className='navbar-section d-flex align-items-center'>
                    <ul className='navbar-list'>
                        <li className='navbar-items d-mobile-none'>
                            <a href='/' className='navbar-item active'>home</a>
                        </li>
                        <li className='navbar-items d-mobile-none'>
                            <a href='/community/' className='navbar-item'>community</a>
                        </li>
                        <li className='navbar-items d-mobile-none'>
                            <a href='/compare/' className='navbar-item'>compare</a>
                        </li>
                        <li className='navbar-items d-mobile-none'>
                            <a href='/brands/' className='navbar-item'>brands</a>
                        </li>
                        <li className='navbar-items d-mobile-none'>
                            {/* <div class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    CATEGORIES <AiOutlineDown/>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-dark">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div> */}
                            {/* <NavDropdown
                                title="CATEGORIES"
                            >
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </li>

                        <li className='navbar-items '>
                            <a href='/explore/' className='navbar-item d-flex'>explore
                                &nbsp;
                                <FaSearch style={{ fontSize: '1.3rem' }} />
                            </a>
                        </li>
                    </ul>
                    <div className='navbar-section-end me-3'>
                        &nbsp;

                        <a className='navbar-section-end me-3' href='#'>
                            <Button style={{ background: "None", border: "None" }} onClick={checkLogin}><FaUserCircle style={{ fontSize: '2rem', color: '#fff' }} /></Button>
                        </a>
                    </div>
                </div>
            </section>
            <section className='mobileNav' style={{ display: mobileMenu ? 'flex' : 'none' }}>
                <div className='navbar-section mobile-menu' style={{ backgroundImage: `url(${background})` }}>
                    <ul className='navbar-list'>
                        <li className='navbar-items'>
                            <a href='/' className='navbar-item active'>home</a>
                        </li>
                        <li className='navbar-items'>
                            <a href='/community/' className='navbar-item'>community</a>
                        </li>
                        <li className='navbar-items'>
                            <a href='/compare/' className='navbar-item'>compare</a>
                        </li>
                        <li className='navbar-items'>
                            <a href='/brands/' className='navbar-item'>brands</a>
                        </li>
                        <li className='navbar-items'>
                            <NavDropdown
                                title="CATEGORIES"
                            >
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown>
                        </li>
                        <li className='navbar-items'>
                            <a href='/explore/' className='navbar-item'>explore</a>
                        </li>
                    </ul>
                </div>
            </section>

            {/* LOGIN  */}
            <div className="modal" style={{ display: enableLogin ? 'flex' : 'none' }}>
                <form method='POST' className='modal-content' onSubmit={handleSubmit}>
                    <Typography variant='h4' fontWeight={600} marginTop={0} color='primary'>Account Login</Typography>
                    <br />
                    <TextField required type='email' label="Email Account" variant="standard" onChange={(e) => setUsername(e.target.value)} />
                    <TextField required type='password' label="Password" variant="standard" onChange={(e) => setPassword(e.target.value)} />
                    <br />
                    <div style={{ marginLeft: 8 }}>
                        <FormControlLabel control={<CheckBox />} label="Remember Me" />
                    </div>
                    <br />
                    <Typography><b>Not Registered? <a href='#' style={{ textDecoration: 'none', color: '#1976D2' }} >Signup</a></b></Typography>
                    <br />
                    <div id='action-buttons'>
                        <Button variant="contained" type='submit' onClick={handleSubmit} style={{ left: "30%", width: "40%" }} color='primary' >Login</Button>
                    </div>
                </form>
            </div>

            
        </>
    )
}

export default Navbar